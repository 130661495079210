import React, { useState, useEffect, useContext } from "react";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import axios from "axios";
import { ThemeContext } from "../../contexts/ThemeContext";
import { Swiper, SwiperSlide } from "swiper/react";
import { BUILDER_URL } from '../../utils/config';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import CustomImage from '../../components/custom/CustomImage';
import * as Sentry from "@sentry/react";
import { useJobId } from '../../contexts/ContextJbo';
import errorLogApi from '../../components/custom/ErrorLogApi';

SwiperCore.use([Navigation, Pagination, Autoplay]);

const BASE_URL = BUILDER_URL;

const CategorySlider = ({ widgetId, onImageLoad }) => {
  const [categories, setCategories] = useState([]);
  const [categoriesData, setCategoriesData] = useState([]);
  const [categoryName, setCategoryName] = useState("");
  const [responseData, setResponseData] = useState(null);
  const [sliderConfig, setSliderConfig] = useState({
    sliderHeader: "",
    numberOfProduct: 1,
    rotationSeconds: 2,
  });

  const theme = useContext(ThemeContext);
  const { jboId } = useJobId();

  useEffect(() => {
    if (!widgetId) return;

    const fetchData = (endpoint, setter) => {
      axios
        .get(`${BASE_URL}/${endpoint}/${widgetId}`, {
          headers: {
            Authorization: `Bearer ${theme.token}`,
          },
        })
        .then((response) => {
          if (setter === setCategories) {
            setter(response.data);
          } else if (
            response.data &&
            response.data.length > 0 &&
            response.data[0].menu_deatil
          ) {
            setter(response.data[0].menu_deatil);
          }

          if (
            setter === setCategoriesData &&
            response.data &&
            response.data.length > 0 &&
            response.data[0].menu_deatil &&
            response.data[0].menu_deatil.length > 0
          ) {
            setCategoryName(response.data[0].widget_name);
          }
        })
        .catch((error) => {
          const jboID = jboId;
          // const endpoint = `${endpoint}/${widgetId}`;
          const payLoad = "";
          const responseData = "";
          const errorMsg = error;
          errorLogApi({
            job_id: jboID,
            // endpoint: endpoint,
            payload: payLoad,
            responseData: responseData,
            errorMsg: errorMsg,
          });
          Sentry.captureException(error);
        });
    };

    const fetchSliderConfig = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/category-slider/${widgetId}`,
          {
            headers: {
              Authorization: `Bearer ${theme.token}`,
            },
          }
        );
        const data = response.data[0];
        setResponseData(data);
        setSliderConfig({
          sliderHeader: data.slider_header,
          numberOfProduct: data.number_of_product,
          rotationSeconds: data.rotation_seconds,
        });
        setCategoriesData(data.menu_deatil);
      } catch (error) {
        const jboID = jboId;
        // const endpoint = `category-slider/${widgetId}`;
        const payLoad = "";
        const responseData = error?.response?.data ? error.response.data : "";
        const errorMsg = error;
        errorLogApi({
          job_id: jboID,
          // endpoint: endpoint,
          payload: payLoad,
          responseData: responseData,
          errorMsg: errorMsg,
        });
        console.error(`Error fetching slider config:`, error);
      }
    };

    fetchData("category-slider/preview", setCategories);
    fetchSliderConfig();
  }, [widgetId]);

  if (!responseData) {
    return (
      <div className="space-y-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {Array(3).fill().map((_, index) => (
            <div key={index} className="flex flex-col items-center p-4 ">
              <div className="w-32 h-32 md:w-40 md:h-40 lg:w-48 lg:h-48 bg-gray-300 rounded-lg mb-4"></div>
              <div className="h-4 bg-gray-300 rounded w-3/4 mb-2"></div>
              <div className="h-4 bg-gray-300 rounded w-1/2"></div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  function isBase64(str) {
    try {
      return btoa(atob(str)) === str;
    } catch (err) {
      return false;
    }
  }

  const isLargeScreenEnabled =
    categories.length > 5 || categories.length > sliderConfig.numberOfProduct;
  const isSmallScreenEnabled = sliderConfig.numberOfProduct >= 2;

  return (
    <div className="block xl:container px-4 mb-2 lg:mb-4 min-h-[100px]">
      <h1 className="text-xl lg:text-2xl px-3 lg:px-0 text-primary text-center font-semibold mb-2 lg:mb-4 truncate">
        {/* Truncate the title - layout shifts */}
        <div className="truncate max-w-full">{responseData.widget_title}</div>
      </h1>
      <div className="relative">
        {(isLargeScreenEnabled || isSmallScreenEnabled) && (
          <>
            <div
              className={`swiper-button-prev flex items-center justify-center w-10 h-10 bg-gray-700 text-white rounded-full absolute top-[50%] md:top-[42%] transform -translate-y-1/2 left-0 cursor-pointer ${
                isLargeScreenEnabled ? "" : "lg:hidden xl:hidden"
              }`}
              style={{
                zIndex: 10,
              }}
            >
              <FaArrowLeft />
            </div>
            <div
              className={`swiper-button-next flex items-center justify-center w-10 h-10 bg-gray-700 text-white rounded-full absolute top-[50%] md:top-[42%] transform -translate-y-1/2 right-0 cursor-pointer ${
                isLargeScreenEnabled ? "" : "lg:hidden xl:hidden"
              }`}
              style={{
                zIndex: 10,
              }}
            >
              <FaArrowRight />
            </div>
          </>
        )}
        {isLargeScreenEnabled || isSmallScreenEnabled ? (
          <Swiper
            key={`swiper-key-${sliderConfig.numberOfProduct}`}
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            }}
            autoplay={{
              delay: sliderConfig.rotationSeconds * 1000,
              disableOnInteraction: false,
            }}
            breakpoints={{
              320: { slidesPerView: 2 },
              640: { slidesPerView: 2 },
              768: { slidesPerView: 3 },
              1024: { slidesPerView: sliderConfig.numberOfProduct },
              1440: { slidesPerView: sliderConfig.numberOfProduct },
            }}
          >
            {categories.map((category, index) => (
              <SwiperSlide key={index} className="flex justify-center gap-2">
                <a
                  href={`${
                    (categoriesData[index] &&
                      categoriesData[index].menu_link) ||
                    ""
                  }`}
                  className="flex flex-col items-center justify-center transition cursor-pointer"
                >
                  <div className="mb-2 w-32 h-32 md:w-40 md:h-40 lg:w-48 lg:h-48 bg-gray-50 flex items-center justify-center overflow-hidden rounded-lg">
                    {category && (
                      <>
                        <CustomImage
                          src={
                            isBase64(category.image_url)
                              ? `data:image/jpeg;base64,${category.image_url}`
                              : category.image_url
                          }
                          alt={category.product_name}
                          width={192} // Fixed width
                          height={192} // Fixed height
                          className="object-cover" // Prevent any resizing or shifting
                        />
                      </>
                    )}
                  </div>
                  <span className="text-sm md:text-lg text-center font-medium capitalize text-gray-800 hover:text-gray-600 transition sm:whitespace-normal sm:w-auto truncate">
                    {/*  layout shifts */}
                    {category.product_name}
                  </span>
                </a>
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          <div className="flex flex-wrap justify-center gap-2">
            {categories.map((category, index) => (
              <div key={index} className="flex justify-center gap-2">
                <a
                  href={`${
                    (categoriesData[index] &&
                      categoriesData[index].menu_link) ||
                    ""
                  }`}
                  className="flex flex-col items-center justify-center transition cursor-pointer"
                >
                  <div className="mb-2 w-32 h-32 md:w-40 md:h-40 lg:w-48 lg:h-48 bg-gray-50 flex items-center justify-center overflow-hidden rounded-lg">
                    {category && (
                      <>
                        <CustomImage
                          src={
                            isBase64(category.image_url)
                              ? `data:image/jpeg;base64,${category.image_url}`
                              : category.image_url
                          }
                          alt={category.product_name}
                          width={192} // Fixed width
                          height={192} // Fixed height
                          className="object-cover" // Prevent any resizing or shifting
                        />
                      </>
                    )}
                  </div>
                  <span className="text-sm md:text-lg text-center font-medium capitalize text-gray-800 hover:text-gray-600 transition sm:whitespace-normal sm:w-auto truncate">
                     {/* layout shifts  */}
                    {category.product_name}
                  </span>
                </a>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default CategorySlider;
